<template>
    <div>
      <div class="p-3 bg-white rounded-xl shadow-lg mx-4">
        <div class="grid grid-cols-2" @click="showFilters = !showFilters">
          <div class="flex items-center gap-4">
            <a-icon type="filter" class="text-lg ml-2"></a-icon>
            <h2 class="text-left text-lg text-gray-600">
              <b>Filtros de búsqueda</b>
            </h2>
          </div>
          <span
            class="h-5 w-5 m-2 flex justify-center items-center transition justify-self-end"
            :class="{ 'transform rotate-180': showFilters }"
          >
            <a-icon type="caret-down" class="text-xl my-auto" />
          </span>
        </div>
        <a-divider v-if="showFilters" class="mt-2 mb-2"></a-divider>
        <div
          class="flex gap-5 flex-wrap relative w-full border-box"
          v-show="showFilters"
        >
          <div
            @keypress.enter="!disabled || !disableButton ? onSearch() : ''"
            class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 relative"
            :class="
              management && optionsProducts.length !== 0 ? ' xl:grid-cols-4' : ''
            "
          >
            <BaseSelect
              ref="company"
              id="entidad"
              label="Entidad"
              :selectOptions="formOptions.optionsCompanies"
              v-model="company"
              :loading="loading"
              :disabled="$store.state.session.user.person.position === 21"
            />
            <BaseSelect
              v-if="optionsProducts.length === 0"
              ref="product"
              id="producto "
              label="Producto"
              :selectOptions="formOptions.optionsProducts"
              v-model="form.producto_cod"
              :value="productValue"
              :loading="loading"
            />
            <BaseSelect
              v-if="optionsReasons.length === 0"
              ref="reason"
              id="reason"
              label="Motivo"
              :selectOptions="formOptions.optionsReasons"
              v-model="form.macro_motivo_cod"
              :value="reasonValue"
              :loading="loading"
            />
            <BaseSelect
              v-if="optionsProducts.length > 0"
              ref="product"
              id="producto"
              label="Producto"
              :selectOptions="optionsProducts"
              v-model="form.producto_cod"
              :value="productValue"
              :loading="loading"
            />
            <BaseSelect
              v-if="optionsReasons.length > 0"
              ref="reason"
              id="reason"
              label="Motivo"
              :selectOptions="optionsReasons"
              v-model="form.macro_motivo_cod"
              :value="reasonValue"
              :loading="loading"
            />

            <BaseInput
              type="text"
              label="Código interno"
              placeholder="Ej: 123456789"
              v-model="form.codigo_queja"
            />

            <BaseSelect
              ref="favorability"
              id="favorability"
              label="Tipo de solicitud"
              :selectOptions="formOptions.optionsTypeRequestSolip"
              v-model="form.solicitude_type"
              :value="favorabilityValue"
              :loading="loading"
            />
              
            <BaseSelect
              ref="states"
              id="estado"
              label="Estado de radicación"
              class="self-end"
              :selectOptions="formOptions.optionsStatesSolip"
              v-model="form.status_solip"
              :loading="loading"
            />
            
            <BaseSelect
              ref="states"
              id="estado"
              label="Estado de radicación de anexos"
              class="self-end"
              :selectOptions="formOptions.optionsStatesAnnexesSolip"
              v-model="form.solip_status_annexes"
              :loading="loading"
            />
                
            <BaseSelect
              ref="Instancia de recepción"
              id="Instancia de recepción"
              label="Instancia de recepción"
              :selectOptions="formOptions.optionsReception"
              v-model="form.insta_recepcion__id"
              v-if="!duplicate"
              :loading="loading"
            />

            <BaseSelect
              ref="identificacion"
              id="identification"
              label="Tipo de identificación"
              :selectOptions="formOptions.optionsIdentificationType"
              v-model="form.tipo_id_CF"
              :loading="loading"
            />

            <BaseRangeDate
              ref="Fecha de creacion"
              label="Fecha de creación"
              v-if="!duplicate"
              @notify-dates="setRangeDate($event)"
            />
            
            <BaseInput
              type="text"
              label="Nombre"
              placeholder="Ej: Fulano Lopez"
              v-model="form.nombres"
            />
            <BaseInput
              type="text"
              label="Nombre del apoderado"
              placeholder="Ej: Sutano Ramirez"
              v-model="form.apoderado_nombre"
            />
            <div>
              <BaseInput
                type="text"
                label="No. de identificación"
                placeholder="Ej: 1144578546"
                v-model="form.numero_id_CF"
                :identification="form.tipo_id_CF"
              />
            </div>
  
            <div>
              <BaseInput
                ref="email"
                type="email"
                label="Correo electrónico"
                placeholder="username@example.com"
                v-model="form.email"
                v-if="!duplicate"
              />
              <p v-if="feedback" class="mt-2 text-xs text-red-500">
                {{ feedback ? feedback : "" }}
              </p>
            </div>
            <!--<div>
              <BaseInput
                type="text"
                label="Dirección física"
                placeholder="Cra. 1 # 1A-11"
                v-model="form.address"
            />
            </div>-->
            <div>
              <BaseInput
                ref="emailNotification"
                type="email"
                label="Correo electrónico de notificación"
                placeholder="emailnotification@example.com"
                v-model="form.email_notification"              
              />
              <p v-if="feedbackEmailNotification" class="mt-2 text-xs text-red-500">
                {{ feedbackEmailNotification ? feedbackEmailNotification : "" }}
              </p>
            </div>
          </div>
          <div class="w-full flex justify-end self-end gap-4">
            <a-button
              class="w-1/2 gap-2 sm:mt-auto mt-3 lg:w-1/6"
              type="danger"
              ghost
              shape="round"
              @click="clearFilters"
            >
              Limpiar
            </a-button>
  
            <a-button
              :loading="(searching = loading)"
              type="primary"
              shape="round"
              @click="onSearch"
              class="w-1/2 gap-2 sm:mt-auto mt-3 lg:w-1/6"
              :disabled="disableButton && disabled"
            >
              <a-icon type="search" v-if="!searching"></a-icon>
              {{ searching ? "BUSCANDO..." : "BUSCAR" }}
            </a-button>
          </div>
          <div
            v-if="loading"
            class="absolute w-full h-full flex items-center justify-center"
          >
            <a-spin>
              <a-icon
                slot="indicator"
                type="loading"
                style="font-size: 64px"
                spin
              />
            </a-spin>
          </div>
        </div>
      </div>
  
      <div v-if="management" class="my-8 mx-4 max-w-sm rounded-xl">
        <a-button
          type="primary"
          shape="round"
          @click.native="openModal"
          :block="true"
        >
          CREAR MUESTRA
        </a-button>
      </div>
      <Modal v-if="showModal">
        <div class="w-full px-4 flex justify-end items-center">
          <a-icon
            type="plus"
            class="text-2xl ml-auto transform text-gray-600 rotate-45"
            @click.native="closeModal"
          />
        </div>
        <div class="pb-4 flex flex-col justify-center items-center">
          <div class="mb-4 w-full max-w-md text-left">
            <h2 class="mb-2 text-2xl text-blue-dark"><b>Crear muestra</b></h2>
            <p class="text-md pb-0">
              Selecciona los datos para crear la muestra de monitoreo
            </p>
            <div class="w-full pb-4">
              <BaseSelect
                label="Entidad"
                :selectOptions="formOptions.optionsCompanies"
                v-model="sample_form.control_entity"
              />
              <BaseSelect
                label="Tipo de criterio a evaluar"
                :selectOptions="formOptions.optionsCriterions"
                v-model="sample_form.criterion"
              />
              <BaseNumber
                label="Cantidad de muestra"
                placeholder="Ej: 200"
                v-model="sample_form.sample_size"
              />
            </div>
          </div>
  
          <div class="w-full max-w-md flex flex-col gap-4">
            <a-button
              type="primary"
              :block="true"
              shape="round"
              @click.native="createSample"
            >
              CREAR MUESTRA
            </a-button>
            <a-button
              type="danger"
              :block="true"
              shape="round"
              ghost
              @click.native="closeModal"
            >
              CANCELAR
            </a-button>
          </div>
        </div>
      </Modal>
    </div>
  </template>
  
  <script>
  export default {
    props: ["management", "query", "loading", "duplicate"],
    data() {
      return {
        form: {
          tipo_id_CF: "",
          numero_id_CF: "",
          company_sfc_code: "",
          tipo_entidad: "",
          producto_cod: "",
          codigo_queja: "",
          status_solip: "",
          solip_status_annexes: "",
          search: "",
          insta_recepcion__id: "",
          email: "",
          apoderado_nombre: "",
          nombres: "",
          solicitude_type: "",
          macro_motivo_cod: "",
          start_date: "",
          end_date: "",
          check_habeas_data: "",
          address: "",
          email_notification: "",
        },
        company: "",
        optionsProducts: [],
        optionsFavorability: [
          { name: "Favorable cosas", id: 1 },
          { name: "Parcialmente Favorable", id: 2 },
          { name: "No Favorable", id: 3 },
        ],
        optionsReasons: [],
        productValue: "",
        reasonValue: "",
        favorabilityValue: "",
        sample_form: {
          control_entity: "",
          criterion: "",
          sample_size: "",
        },
        showModal: false,
        feedback: "",
        searching: false,
        disableButton: true,
        showFilters: false,
        oldFilters: {},
        feedbackEmailNotification: "",
      };
    },
  
    watch: {
      company: async function (value) {
        if (value == "") {
          this.form.company_sfc_code = "";
          this.form.tipo_entidad = "";
          this.optionsProducts = [];
          this.optionsReasons = [];
          return;
        }
        this.productValue = "";
        this.reasonValue = "";
        let optionsProducts = await this.$store.dispatch(
          "form/loadProductsOptions",
          { company: this.company }
        );
  
        let optionsReasons = await this.$store.dispatch(
          "form/loadReasonsOptions",
          { company: this.company }
        );
        const companyData = this.$store.state.form.optionsCompanies.find(
          (item) => item.id == this.company
        );
        this.form.company_sfc_code = companyData.sfc_code;
        this.form.tipo_entidad = companyData.company_type;
        this.optionsProducts = optionsProducts ? optionsProducts : [];
        this.optionsReasons = optionsReasons ? optionsReasons : [];
      },
    },
  
    methods: {
      onSearch() {
        this.searching = true;      
        if (this.form.codigo_queja && this.form.codigo_queja !== "") {
          this.form.codigo_queja = this.form.codigo_queja.trim();
        }
        if(this.form.email_notification && this.form.email_notification!=="" && !/^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(this.form.email_notification)) {
          this.feedbackEmailNotification = "Por favor, verifique el correo electrónico.";
          return;
        }else{
          this.feedbackEmailNotification = "";
        }
        if (this.form.email && this.form.email !== "") {
          if (
            /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
              this.form.email
            )
          ) {
            this.$emit("fetch-complaints", this.form);
            this.feedback = "";
          } else this.feedback = "Por favor, verifique el correo electrónico.";
        } else if (this.changedFilters()) {
          this.$emit("fetch-complaints", this.form);
          this.oldFilters = this.form;
          this.feedback = "";
        }
      },
      setRangeDate(eventValue) {
        this.form.start_date = eventValue[0];
        this.form.end_date = eventValue[1];
        this.disableButton = false;
      },
      getCompany() {
        if (this.form.company_sfc_code && this.form.company_sfc_code !== "") {
          const companyData = this.$store.state.form.optionsCompanies.find(
            (item) =>
              item.sfc_code == this.form.company_sfc_code &&
              item.company_type == this.form.tipo_entidad
          );
          this.company = companyData.id + "";
        }
      },
      clearFilters() {
        this.form = {};
        this.$emit("fetch-complaints", this.form);
        this.setCompanyForEV();
      },
      setCompanyForEV() {
        this.company = this.$store.state.session.user.person.position == 21
            ? `${this.$store.state.session.user.id_company.id || ''}`
            : ""
      },
      changedFilters() {
        // let aKeys = Object.keys(this.form).sort();
        // let bKeys = Object.keys(this.oldFilters).sort();
        // if (aKeys.length != bKeys.length) return true;
        // for (let i = 0; i < aKeys.length; i++) {
        //   if (this.form[aKeys[i]] !== this.oldFilters[bKeys[i]]) {
        //     return true;
        //   }
        // }
        return true;
      },
      async createSample() {
        const { sample_size, control_entity, criterion } = this.sample_form;
        const body = {
          sample_quantify: parseInt(sample_size),
          entities: [parseInt(control_entity)],
          criterion: parseInt(criterion),
        };
  
        this.loading = true;
        let { error, data } = await this.$api.createSample(body);
        this.loading = false;
  
        if (error) this.showNotification(error.message);
  
        if (data) {
          this.showNotification("Muestra creada");
          this.$router.push(`/dashboard/complaints/management/samples`);
        }
      },
  
      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
      showNotification(message) {
        this.$notification.open({
          message: "Notificación",
          description: message,
        });
      }
    },
    computed: {
      formOptions: function () {
        return this.$store.state.form;
      },
      disabled: function () {
        // let isFormEmpty = true;
        // Object.values(this.form).forEach((value) => {
        //   isFormEmpty = isFormEmpty && !value;
        // });
        // return isFormEmpty;
        return false;
      },
    },
    created() {
      this.form = { ...this.$props.query };
  
      this.oldFilters = { ...this.form };
      setTimeout(() => {
        this.getCompany();
        this.setCompanyForEV();
      }, 1500);
    },
  };
  </script>