<template>
  <div class="w-full relative">
    <div class="mb-4">      
      <div
        class="mx-4 bg-white shadow-xl rounded-lg flex justify-between items-center cursor-pointer p-3"
        @click="toggleAccordion">
        <h1 class="text-xl text-blue font-bold text-left">Generación manual de información para radicación en solip</h1>
        <span
          class="h-5 w-5 m-2 flex justify-center items-center transition"
          :class="{ 'transform rotate-180': accordionOpen }"
        >
          <img src="@/assets/icons/arrow-down.svg" alt="" />
        </span>
      </div>
      <div v-if="accordionOpen" class="py-3 mx-6 pb-6 shadow-xl">
        <SolipExportOldView/>
      </div>
    </div>    
    <hr class="mx-4">
    <h1 class="mb-4 mt-10 ml-6 text-xl text-blue font-bold text-left">Radicación Solip</h1>
    <TotalComplaints :body="'conciliaciones y/o demandas registradas'" :total="count" :title="'Total de conciliaciones y demandas'" />

    <ComplaintsFilterSolip @fetch-complaints="setQueries" :query="start_query" :loading="loading" />
    <div class="py-12" v-if="legend">
      <b class="text-center text-4xl mx-auto text-gray-400">{{ legend }}</b>
    </div>
    <div v-if="loading" class="absolute w-full justify-center mt-7">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <div v-else class="bg-white overflow-x-auto rounded-xl border border-gray-300 mx-4 mt-4 search-complaints">
      <div class="p-4 flex justify-start">
        <a-menu mode="horizontal">
          <a-sub-menu>
            <a-button slot="title" type="primary" style="margin-bottom: 16px" v-on:click="collapsed = !collapsed">
              <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
            </a-button>
            <a-menu-item v-if="collapsed" v-on:click="generateInfoSolip('xlsx')">
              <span class="mx-2">
                <a-icon type="download" :style="{ fontSize: '16px' }" class="mr-2" />
                .XLSX</span>
            </a-menu-item>
            <a-menu-item v-if="collapsed" v-on:click="generateInfoSolip('csv')">
              <span class="mx-2">
                <a-icon type="download" :style="{ fontSize: '16px' }" class="mr-2" />
                .CSV (Separado por comas)</span>
            </a-menu-item>
            <a-menu-item v-if="collapsed" v-on:click="generateInfoSolip('txt')">
              <span class="mx-2">
                <a-icon type="download" :style="{ fontSize: '16px' }" class="mr-2" />.txt</span>
            </a-menu-item>
            <!--
              <a-menu-item v-if="collapsed && loading">
                <div v-if="loading">
                  <progress :value="progress" max="100"></progress>
                  <p>{{ progress }}%</p>
                </div>
              </a-menu-item>
              -->
          </a-sub-menu>
        </a-menu>
      </div>
      <a-table :columns="columns" rowKey="document_id" :data-source="dataRows" :pagination="false"
        :scroll="{ x: '1220px' }">
        <div slot="cf_name" class="title-size font-bold text-gray-500 text-center">
          <p>Nombre del CF</p>
        </div>
        <div slot="company_name" class="title-size font-bold text-gray-500">
          Entidad
        </div>
        <div slot="document_id" class="flex flex-row justify-items-center title-size font-bold text-gray-500">
          Código interno
        </div>
        <div slot="solicitude_type" class="title-size font-bold text-gray-500">
          Solicitud
        </div>
        <div slot="status_solip_to_view" class="title-size font-bold text-gray-500">
          Estado de radicación
        </div>
        <div slot="status_solip_annexes_to_view" class="title-size font-bold text-gray-500">
          Estado de radicación de anexos
        </div>
        <div slot="created_at" class="title-size font-bold text-gray-500">
          Fecha
        </div>
        <!-- Columna Acciones -->
        <div slot="actions" class="title-size font-bold text-gray-500">
          Acciones
        </div>
        <div slot="actions" slot-scope="text, record" class="flex flex-row justify-items-center">
          <!--retry-->
          <button
            v-if='record.status_solip == "Error en radicacion"'
            class="flex justify-center text-xs mx-auto text-blue h-min" @click="reloadRadicadoSolip(record.document_id)">
            <a-icon type="reload" :style="{ fontSize: '19px', color: '#3366cc' }" />
          </button>
          <!--blue eye-->
          <button v-on:click="solicitudeDetail($event, record)"
            v-if="(record.status_solip == 'Radicada' && record.status_solip_annexes == 'Anexo(s) radicado(s)') || (!record.status_solip && !record.status_solip_annexes)"
            class="flex justify-center text-xs mx-auto text-blue h-min">
            <a-icon type="eye" :style="{ fontSize: '19px', color: '#3366cc' }" />
          </button>
          <!--red eye-->
          <button v-on:click="solicitudeDetail($event, record)"
            v-if="record.status_solip == 'Error en radicacion' || record.status_solip_annexes == 'Error en radicacion de anexo(s)'"
            class="flex justify-center text-xs mx-auto text-blue h-min">
            <a-icon type="eye" :style="{ fontSize: '19px', color: '#e02424' }" />
          </button>
        </div>
      </a-table>

      <div class="p-4 flex justify-end">
        <a-pagination v-model="page" :page-size="pageSize" :page-size-options="pageSizeOptions" :total="count"
          show-size-changer size="large" @showSizeChange="onShowSizeChange" />
      </div>
    </div>    
    <Modal v-if="viewModal" size="md">
      <div class="text-center">
        <p class="mt-4">{{msgToModal}}</p>
        <button @click="closeModal" class="mt-4 py-2 px-4 bg-blue-500 text-white rounded-lg">ACEPTAR</button>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from "@/components/UI/Modal.vue";
import ComplaintStatusLabel from "./ComplaintStatusLabel";
import ComplaintsFilterSolip from "./ComplaintsFilterSolip";
import TotalComplaints from "./TotalComplaints.vue";
import capitalize from "@/utils/checkForms.js";
import formatDate from "@/utils/formatDate.js";
import moment from "moment";
import { format } from "date-fns";
import JSZip from 'jszip';
import { conformsTo } from "lodash";
import { reduce } from "lodash";
import SolipExportOldView from "./solipExportOldView.vue";


const columnText = {
  codigo_queja: "Id. de radicado",
  nombres: "Responsable",
  company_name: "Entidad",
  macro_motivo_cod__name: "Motivo de la queja",
  estado_cod__name: "Estado",
  complaint_type: "Queja principal",
  creation_date: "Fecha de creación",
  actions: "Acciones",
}

const columns = [
  {
    slots: { title: "cf_name" },
    dataIndex: "cf_name",
    key: "cf_name",
    width: 100,
  },
  {
    slots: { title: "company_name" },
    dataIndex: "company_name",
    key: "company_name",
    width: 110,
  },
  {
    slots: { title: "document_id" },
    dataIndex: "document_id",
    key: "document_id",
    width: 100,
  },
  {
    slots: { title: "solicitude_type" },
    dataIndex: "solicitude_type",
    key: "solicitude_type",
    width: 100,
  },
  {
    slots: { title: "status_solip_to_view" },
    dataIndex: "status_solip_to_view",
    key: "status_solip_to_view",
    width: 150,
  },
  {
    slots: { title: "status_solip_annexes_to_view" },
    dataIndex: "status_solip_annexes_to_view",
    key: "status_solip_annexes_to_view",
    width: 150,
  },
  {
    slots: { title: "created_at" },
    dataIndex: "created_at",
    key: "created_at",
    width: 150,
  },
  {
    slots: { title: "actions" },
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
    width: 75,
  },
];

const columnsExcelBackup = columns.filter(c => c.dataIndex !== "actions").map(c => ({
  label: columnText[c.slots.title],
  field: c.dataIndex
}))
// add new fields
columnsExcelBackup.push(
  { label: "Medio de notificación", field: "insta_recepcion" },
  { label: "Dirección física", field: "address" },
  { label: "Correo electrónico", field: "email" },
  { label: "Correo de notificación", field: "email_notification" }
);
export default {
  components: {
    Modal,
    ComplaintsFilterSolip,
    TotalComplaints,
    SolipExportOldView,
  },
  data() {
    return {
      // Excel
      collapsed: false,
      loadingReport: false,
      complaintsInfo: [],
      columnsExcel: columnsExcelBackup,
      json_data: [],
      json_csv: [],
      filtersToDownload: { columns: [], data: [] },
      pageSizeDownload: 1000,
      file_name: "Búsqueda de quejas (Completo)",
      progress: 0,
      // End Excel
      columns: columns,
      form: {},
      legend: "",
      count: 0,
      complaints: [],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      page: 1,
      showModal: false,
      pageSize: 100,
      start_query: {},
      pageSizeOptions: ["50", "100", "150", "200", "250"],
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      filterSearch: "",
      step: 1,
      visibleDrawer: false,
      spinner: false,
      timeoutId: null,
      percent: 0,
      showTab: -1,
      showLimit: false,
      fileStatus: 'Enviando solicitud...',
      fileStatusDetail: '',
      socket: null,
      download_in_process: false,
      drawerInfo: [
        {
          email: 'Sebas',
          name: { last: 'Acevedo' },
          href: 'url'
        }
      ],
      dataTable: {
        header: [
          { title: "Nombre del CF", style: "w-20" },
          { title: "Entidad", style: "w-15" },
          { title: "Codigo interno", style: "w-15" },
          { title: "Contexto", style: "w-20" },
          { title: "Solicitud", style: "w-15" },
          { title: "Acciones", style: "w-15" },
        ],
        content: [],
      },
      initForm: {},
      totalPages: 1,
      dataRows: [],
      msgToModal: "",
      viewModal: false,
      accordionOpen: false,
    };
  },
  created() {
    if ( !this.form.start_date || this.form.start_date == "" ) {
      const start_date = moment()
        .set(new Date())
        .subtract(7, "d")
        .format("YYYY-MM-DD");
      const end_date = moment().set(new Date()).format("YYYY-MM-DD");

      this.form.start_date = start_date;
      this.form.end_date = end_date;
    }
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    if (this.$route.query.pageSize) {
      this.pageSize = parseInt(this.$route.query.pageSize);
    } else {
      this.pageSize = 100;
    }

    this.undoneQuery();
    this.getData(this.form);
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.updateRouterQuery();
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    "$route.query.page": function () {
      this.getData(this.form);
    },
  },
  methods: {
    async getData(form) {
      this.loading = true;
      let data = {};
      try {
        this.spinner = true;
        let query = `?page_size=${this.pageSize}&page=${this.page}`;
        this.form = { ...form };        
        let query_params = this.makeQuery();
        query += query_params ? `&${query_params}` : "";
        const urlService1 = `/jurisdiccional-servi/complaint-management${query}`;
        const { error, data: dataPivot } = await this.$api.getResource(urlService1);
        this.spinner = false;

        if (error) {
          console.log(error);
          this.$notification.error({
            message: "Error",
            description: "Ocurrió un error en la consulta",
          });
        } else {
          data = dataPivot;
          if (Array.isArray(data.data)) {
            
            this.dataRows = data.data.map((item) => ({
              ...item,              
              status_solip_to_view: <ComplaintStatusLabel status={item.status_solip} danger="true" />,
              status_solip_annexes_to_view: <ComplaintStatusLabel status={item.status_solip_annexes} danger="true" />,
            }));            
            this.dataRows = this.dataRows.map((item) => ({
              ...item,
              solicitude_type:item.solicitude_type == 'conciliation' ? 'conciliacion' : 'demanda',              
              created_at: this.formatDateToText(item.created_at),
            }));    
          } else if (typeof data.data === "object") {            
            this.dataRows = Object.values(data.data).map((item) => ({              
              ...item,
              status_solip_to_view: <ComplaintStatusLabel status={item.status_solip} danger="true" />,
              status_solip_annexes_to_view: <ComplaintStatusLabel status={item.status_solip_annexes} danger="true" />,
            }));
            this.dataRows = this.dataRows.map((item) => ({
              ...item,
              solicitude_type:item.solicitude_type == 'conciliation' ? 'conciliacion' : 'demanda',              
              created_at: this.formatDateToText(item.created_at),
            }));  
          } else {
            this.dataRows = [];
          }
          this.count = data.count || 0;
        }
      } catch (e) {
        console.log("Err in getData", e);
        this.spinner = false;
        this.$notification.error({
          message: "Error",
          description: "Ocurrió un error",
        });
      }

      this.loading = false;
    },
    async solicitudeDetail(checked, record) {
      this.$router.push({ 'name': 'soliditude-detail', params: { id: record.document_id } })
    },
    async reloadRadicadoSolip(document_id) {      
      try {
        let query = '?doc_id=' + document_id;
        const urlRequest = `/jurisdiccional-servi/retry-solip-upload${query}`;        
        const {error, data: response} = await this.$api.getResource(urlRequest);
        if (error) {
          throw error;
        }
        this.msgToModal = response.radicado;
        this.viewModal = true;                         
      } catch (error) {
        console.log('Error en reloadRadicadoSolip', error);
        this.$notification.error({
          message: 'Error',
          description: 'Ocurrió un error al intentar reenviar el radicado',
        });       
      }        
    },
    complaintDetails(checked, record) {
      this.$router.push({
        name: "ComplaintDetails",
        query: { complaint_id: record.codigo_queja },
      });
    },
    setQueries(myForm) {
      this.legend = "";
      this.form = myForm;
      this.showToast("info", "Buscando resultados...");
      if (1 == this.$route.query.page || !this.$route.query.page) {
        this.updateRouterQuery();
        this.getData(this.form);
      }
      this.page = 1;
    },
    undoneQuery() {
      if (this.$route.query?.params) {
        let queryArray = this.$route.query?.params.split("&");
        queryArray.forEach((element) => {
          let param = element.split("=");
          this.form[param[0]] = param[1];
        });
        this.start_query = this.form;
      }
    },
    openNotification() {
      this.$notification.open({
        key: "Id_DescargaArchivos",
        duration: 0,
        message: 'Preparando archivo',
        description:
          <a-spin tip="Cargando">
            <div class="spin-content text-center align-center">
              <a-icon style="font-size: 20px" type="file-excel" />
              Se está generando el archivo, por favor espere.
            </div>
          </a-spin>,
        onClick: () => {

          console.log('Notification Clicked!');
        },
      });
    },
    makeQuery() {
      if (this.form) {
        let query_params = Object.keys(this.form)
          .map((key) => {
            if (this.form[key] && this.form[key] !== "") {
              return key + "=" + this.form[key];
            }
          })
          .filter((data) => !!data)
          .join("&");
        return query_params;
      }
      return null;
    },
    updateRouterQuery() {
      this.$router
        .replace({
          query: {
            page: this.page,
            params: this.makeQuery(),
          },
        })
        .catch((error) => error);
    },
    onShowSizeChange(page, page_size) {      
      this.page = page;
      this.pageSize = page_size;
      this.getData(this.form);      
    },
    async generateInfoSolip(type_file = 'xlsx') {
      this.loading = true;
      this.spinner = true;
      let query = `?get_file=${type_file}`;
      let query_params = this.makeQuery();
      query += query_params ? `&${query_params}` : "";
      let { data } = await this.$api.getResource(
        `/queries-servi/file/generate_solip_report${query}`
      );
      this.spinner = false;
      this.loading = false;
      const link = document.createElement('a')

      link.href = data.url
      link.setAttribute('download', 'solip_file')
      document.body.appendChild(link)

      link.click();
      await this.getData(this.form);
      this.download_in_process = false;

    },
    //borrrar!!!
    async onGetAllComplaints(doc_type = 'xlsx') {
      this.openNotification();
      this.loading = true;

      if (doc_type == 'xlsx' || doc_type == 'csv' || doc_type == 'txt') {

        const filename = `${this.file_name}.${doc_type}`;

        let query = `?get_file=${doc_type}`;
        let query_params = this.makeQuery();
        query += query_params ? `&${query_params}` : "";

        const promise = this.$api.getComplaintsAllFile(query);

        promise.then((response) => {
          const link = document.createElement('a')

          link.href = response.data.url
          link.setAttribute('download', filename)
          document.body.appendChild(link)

          link.click();
          this.$notification.close("Id_DescargaArchivos")
        })
          .catch((error) => {
            this.loadingReport = false;
            this.$notification.close("Id_DescargaArchivos")

            this.$notification.open({
              message: '¡Ups! Algo salió mal.',
              description:
                'Lo sentimos, pero parece que hay un problema. Intentalo más tarde.',
              icon: <a-icon type="frown" style="color: 'red'" />,
            });

          }
          );
      };

      this.loading = false;

    },//borrar
    async handleMenuClick(e) {
      this.download_in_process = true;
      if (!this.download_in_process) {
        this.$notification.open({
          title: "Notificación",
          message: "Descarga en proceso...",
        });
        return;
      }
      await this.generateInfoSolip(e.key);
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => { },
      });
    },
    delay(n) {
      return new Promise(function (resolve) {
        setTimeout(resolve, n * 1000);
      });
    },
    closeModal() {
      this.viewModal = false;   
      this.getData(this.form);        
    },
    formatDateToText(date) {
      return moment(date).format('D [de] MMMM [de] YYYY, HH:mm:ss');
    },
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen;
    },
  },
};
</script>

<style>
.search-complaints .ant-table-thead>tr>th {
  text-align: center;
}

.title-size {
  font-size: 1.05em;
}
</style>