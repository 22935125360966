<template>
    <div class="h-min-content w-full">
      <ComplaintsTableSolip />
    </div>
  </template>
  
  <script>
  import ComplaintsTableSolip from "@/components/Dashboard/Complaints/ComplaintsTableSolip.vue";
  export default {
    components: {
      ComplaintsTableSolip,
    },
  };
  </script>