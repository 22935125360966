<template>
  <header class="w-4/5 flex flex-col-reverse items-center justify-center lg:flex-row lg:justify-between gap-3 px-4">
      <div
        v-if="loading"
        class="spinner absolute w-full h-full top-0 flex items-center justify-center"
      >
        <a-spin class="">
          <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
        </a-spin>
      </div>
      <BaseDate id="date-input" label="Seleccione fecha" type="text" placeholder="AAAA/MM/DD" class="mb-3"
          v-model="date" :disabled-date="no_hoy_ni_despues" @input="dateChanged" :allowClear="false"></BaseDate>
          <a-dropdown class="w-64 mt-7">
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="xlsx">
                  <a-icon type="upload" :style="{ fontSize: '16px' }" class="mr-2" />
                  .xlsx
                </a-menu-item>
                <a-menu-item key="csv">
                  <a-icon type="upload" :style="{ fontSize: '16px' }" class="mr-2" />
                  .csv (Separado por comas)
                </a-menu-item>
                <a-menu-item key="txt">
                  <a-icon type="upload" :style="{ fontSize: '16px' }" class="mr-2" />
                  .txt
                </a-menu-item>
              </a-menu>
            </template>
            <a-button type="primary" shape="round">
              Generar información para SOLIP
              <a-icon type="down" />
            </a-button>
          </a-dropdown>
          <a-button shape="round" class="mt-7" @click="visibleDrawer = !visibleDrawer">
            Descargar archivos (.zip)
            <a-icon type="file-zip" />
          </a-button>
          <!--load inf  o section-->
          <a-drawer :bodyStyle="{ textAlign: 'left' }" :placement="'top'" :closable="true" @close="ocultarDrawer"
          v-if="visibleDrawer" :visible="true" :mask="false" :get-container="false" :wrap-style="{ position: 'absolute' }"
            :height="120">
            <a-row :gutter="24">
              <a-col class="gutter-row" :span="24">
                <p class="text-lg">
                  {{ fileStatus }}
                </p>
                <a-progress :afterVisibleChange="ocultarDrawer" :stroke-color="{
                  from: '#108ee9',
                  to: '#87d068',
                }" :percent="percent" />
                <p>
                  {{ fileStatusDetail }}
                </p>
              </a-col>
            </a-row>
          </a-drawer>
        </header>
</template>
<script>
import moment from "moment";
export default {  
  data() {
    return {
        date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        visibleDrawer: false,
        percent: 0,
        fileStatus: "",
        fileStatusDetail: "",
        loading: false,
        spinner: false,
        download_in_process: false,
        timeoutId: null,

    };
  },
  methods: {  
    async handleMenuClick(e) {
      this.download_in_process = true;
      if (!this.download_in_process) {
        this.$notification.open({
          title: "Notificación",
          message: "Descarga en proceso...",
        });
        return;
      }
      await this.generateInfoSolip(e.key);
    },    
    async generateInfoSolip(type_file = 'xlsx') {
      this.loading = true;
      this.spinner = true;
      let { data } = await this.$api.getResource(
        `/queries-servi/file/generate_solip_report?time=${this.date}&get_file=${type_file}`
      );
      this.spinner = false;
      this.loading = false;
      const link = document.createElement('a')

      link.href = data.url
      link.setAttribute('download', 'solip_file')
      document.body.appendChild(link)

      link.click();
      this.download_in_process = false;

    },
    ocultarDrawer() {
        this.visibleDrawer2 = false;
    },
    no_hoy_ni_despues(current_date) {
      let isSameOrAfter = current_date && moment().subtract(1, 'day').isBefore(current_date);
      return isSameOrAfter;
    },
    async dateChanged() {
      this.visibleDrawer = false;      
    },
    async startPolling() {
      console.log('startPolling')
      this.pollFunction()
    },
    async pollFunction() {
      try {
        const urlService1 = `/jurisdiccional-servi/mng-service/generate-zip?date=${this.date}`;
        console.log(urlService1)
        const { error, data } = await this.$api.getResource(urlService1);
        if (error) {
          this.fileStatus = 'Error';
          this.fileStatusDetail = 'Parece que  hubo un problema al descargar el archivo';
          this.percent = 0;
          this.stopPolling();
          return;
        }
        const status = data.status;
        if (status == 'finished') {
          this.fileStatus = 'Finalizado';
          this.fileStatusDetail = `Se han generado ${data.count} archivos. La descarga comenzará automáticamente en unos momentos. Por favor, espera...`;
          this.percent = data.percent;

          let a = document.createElement("a");
          a.href = data.url;
          a.target = "_blank";
          a.download = '';

          this.stopPolling();
          a.click();

        }
        else if (status == 'in progress') {
          this.fileStatus = 'Generando archivo...';
          this.fileStatusDetail = `Progreso: ${data.processed_files}/${data.count} archivos completados.`;
          this.percent = data.percent;
          if (this.visibleDrawer) {
            this.timeoutId = setTimeout(this.pollFunction, 1122);
          }
        }
        else if (status == 'created') {
          this.fileStatus = 'Consultando...';
          this.fileStatusDetail = `${data.count} archivos encontrados.`;
          this.percent = data.percent;
          if (this.visibleDrawer) {
            this.timeoutId = setTimeout(this.pollFunction, 1122);
          }
        }
        else {
          this.fileStatus = 'Error';
          this.fileStatusDetail = 'Parece que  hubo un problema al descargar el archivo';
          this.percent = 0;
          this.stopPolling();
        }
      } catch (error) {
        console.error('Error en la petición:', error);
        this.fileStatus = 'Error';
        this.fileStatusDetail = 'Parece que  hubo un problema al descargar el archivo';
        this.percent = 0;
        this.stopPolling()
      }
    },
    stopPolling() {
      console.log('stopPolling')
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
      setTimeout(() => {
        if (this.visibleDrawer) {
          this.visibleDrawer = false;
        }
      }, 3000);
    },
    createSocket() {
      this.socket = io('https://sfc-queries-m3ilso5sxq-uc.a.run.app');

      this.socket.on("estado_archivo", (data) => {
        this.fileStatus = data.estado;
        this.percent = data.percent;
      });
      console.log('Socket creado')
    },
    iniciarProceso() {
      console.log('Socket iniciarProceso')
      try {
        console.log('Socket', this.socket)
        this.socket.emit('iniciar_proceso', {});
      } catch (error) {
        console.log('Socket error', error)
      }

    },    
  },
  watch: {
    visibleDrawer: function (newVal, oldVal) {
      if (newVal === false) {
        this.stopPolling();
      } else {
        this.fileStatus = 'Enviando solicitud...';
        this.fileStatusDetail = ''
        this.percent = 0;
        this.startPolling();
      }
    },
  },
  beforeDestroy() {
    this.stopPolling();
    if (this.socket) {
      console.log('Socket beforeDestroy')
      this.socket.disconnect();
    }
  },
};
</script>